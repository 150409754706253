/**
 * Banner Ad Slider JS
 * Author: Imarc 2025
 */

import Siema from 'siema';

const sliders = document.querySelectorAll('.js-bannerSlider');

sliders.forEach(slider => {
    const bannerSlider = new Siema({
        selector: slider, // Pass the individual element as the selector
        duration: 1000,
        loop: true,
        perPage: 1,
    });

    setInterval(() => bannerSlider.next(), 7000);
});
