/**
 * Global scripts meant for entire app
 * Author: Imarc 2019
 */

/**
 * Dependencies
 */
import '@inkwell/scripts/modules/checkboxSelect';
import '@inkwell/scripts/modules/classtoggle';
import '@inkwell/scripts/modules/inputs';
import '@inkwell/scripts/modules/linkObserver';
import '@inkwell/scripts/modules/printSection';
import '@inkwell/scripts/modules/fieldRequires';
import '@inkwell/scripts/modules/fieldSync';
import 'htmx.org';

import './modules/ckconfig';
import './modules/mobilemenu';
import './modules/printCart';
import './modules/anchorsubmit';
import './modules/mobileTrigger';
import './modules/textToggle';
import './modules/tooltip';
import './modules/reveal';
import './modules/select-highlight';
import './modules/alert';
import './modules/keyboardfocus';
import './modules/dynamicModules';
import './modules/banner-ad';
import './modules/accordions';
import './modules/masonry';
import './modules/progress';
import './modules/tracker';
import './modules/banner-slider';

// Mac Fix
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

if (isMac) {
    document.querySelector('body').classList.add('-mac');
}
